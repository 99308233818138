import React from "react"
import Img from "gatsby-image"
import { Col } from "reactstrap"

export default props => {
    return (
        <Col md={props.md} className="justify-center text-center">

            <div className="text-center" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "20px"

            }}>
                <Img style={{
                    width: "280px",
                    height: "280px",
                    justifyContent: "center",
                    justifySelf: "center",
                    margin: "auto"
                }} fluid={props.img} />
                {props.children}
            </div>
        </Col>
    )
}