import React from "react"
import { Container, Row, Col } from "reactstrap"
import ContentContainer from "../../../Components/pageComponents/contentContainer"

import Image from "../../../Components/pageComponents/imgRound"
import SolutionInfoSection from "../../../Components/pageComponents/solutionsInfoSection"
// import Carousel from "../../../Components/pageComponents/carousel"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/computing/virtualization/Virtualization.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        computer: file(relativePath: { eq: "products/computing/virtualization/Computer Hardware Icons.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ss: file(relativePath: { eq: "products/computing/virtualization/Servers and Storages.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <ContentContainer title="Virtualization" image={data.banner.childImageSharp.fluid}>

            <SolutionInfoSection heading="Products We Offer" title="Virtualization" description="Virtualization primarily turns the user’s hardware into its virtual version. The act of Virtualization enables the users to increase utilization and scalability of the resources and environment." />

            <Container>


                <Row>
                    <Col md="6">
                        <Image img={data.computer.childImageSharp.fluid}>
                            Computer Hardware Platforms
                    </Image>
                    </Col>
                    <Col md="6">
                        <Image img={data.ss.childImageSharp.fluid}>
                            Servers and Storages
                    </Image>
                    </Col>
                </Row>
            </Container>

        </ContentContainer>
    )
}